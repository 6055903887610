<template>
  <div class="action__inner withdrawal-modal">
    <a-form :model="dataSource" :rules="rules" @finish="sendWithdraw" name="withdrawals" ref="withdrawals">
      <a-form-item
        ref="writeOffAccount"
        name="writeOffAccount"
        class="input__wrapper"
        :label="i18n.t('start.writeOffAccount')"
      >
        <a-input
          style="width: 100%"
          :value="
            i18n.t('start.inputAccount', {
              name: i18n.t('start.name' + withdrawAccount.attributes.kind),
              amount: withdrawAccount.attributes.amount,
            })
          "
          class="action__select"
          :disabled="true"
          size="large"
          type="text"
        />
      </a-form-item>
      <a-form-item ref="walletNumber" name="walletNumber" class="input__wrapper" :label="i18n.t('start.walletNumber')">
        <a-select
          style="width: 100%"
          class="action__select"
          :placeholder="i18n.t('start.walletNumber')"
          v-model:value="dataSource.wallet_idx"
          size="large"
          v-if="approvedWallets[0]"
          :required="true"
          @change="walletChange"
        >
          <a-select-option v-for="(wallet, id) in approvedWallets" :value="id" :key="wallet.id">
            <p>
              <i>
                <img
                  :src="require('@/static/img/icon/' + wallet.attributes.system + '.svg')"
                  class="crypto-icon"
                  :alt="wallet.attributes.system"
                />
              </i>
              {{ wallet.attributes.name }}
            </p>
          </a-select-option>
        </a-select>
        <router-link to="/wallets/page/1" v-else>
          <sdButton type="primary" class="action__select">{{ i18n.t('profileView.createNewWallet') }}</sdButton>
        </router-link>
      </a-form-item>
      <!--<a-form-item ref="priority" name="priority" class="input__wrapper" :label="i18n.t('start.priorityWithdrawal')">
        <a-input
          style="width:100%"
          :value="priorityWithdrawal[dataSource.priority_id].label"
          class="action__select"
          :disabled="true"
          size="large"
          type="text"
        />
      </a-form-item>
      <a-form-item
        ref="withdrawalData"
        name="withdrawalData"
        class="input__wrapper"
        :label="i18n.t('start.withdrawalDate')"
      >
        <a-input :value="i18n.d(new Date(withdrawalDate), 'short')" size="large" style="width: 100%" type="text">
        </a-input>
      </a-form-item>-->
      <a-form-item ref="amount" name="amount" class="input__wrapper" :label="i18n.t('start.summ')">
        <a-input
          v-model:value="dataSource.amount"
          size="large"
          style="width: 100%"
          type="number"
          :required="true"
          step="0.01"
        >
          <template #addonAfter>
            <p class="action__title">USDT</p>
          </template>
        </a-input>
        <!--  @change="compSummWithComission"

        <p class="summ__comission">
					
          {{ i18n.t('start.withComission') }}: <span>{{ i18n.n(summWithComission, 'decimal') }} USD</span>
        </p>-->
      </a-form-item>
      <a-alert :message="i18n.t('start.withdrawal_modal.rules')" type="info" show-icon />

      <sdButton
        class="action__button"
        :disabled="isLoading || !dataSource.wallet_id || errorAmount"
        size="large"
        type="primary"
        htmlType="submit"
        raised
      >
        {{ i18n.t('start.withdrawal') }}
      </sdButton>
    </a-form>
    <!--<p class="action__more-info" @click="changePayInfo">
            <sdFeatherIcons type="info" size="20" /> {{ i18n.t('start.moreInfo') }}
          </p>-->
  </div>
  <!--<div class="action__inner" v-if="visiblePayHint">
          <p class="action__info">
            {{ i18n.t('start.actionWithdrawalHintFirst') }} <br />
            <br />
            {{ i18n.t('start.actionWithdrawalHintSecond') }} <br />
            <br />
            {{ i18n.t('start.actionWithdrawalHintThird') }}<br />
            <br />
            {{ i18n.t('start.actionWithdrawalHintFourth') }}
          </p>
        </div>-->
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { depositCurrencies } from '@/config/helpers/JSONdata';
import { useRoute } from 'vue-router';
import { message } from 'ant-design-vue';

const WithdrawalModal = {
  name: 'WithdrawalModal',
  props: { error: Boolean },
  emits: ['dataNotSended', 'dataSended'],
  setup(props, { emit }) {
    const i18n = useI18n();
    const { state, dispatch } = useStore();
    const route = useRoute();
    const accId = computed(() => route.params.accId);

    const dataSource = ref({
      amount: '',
      exchange_currency: 'BTC',
      priority_id: 0,
    });
    const errorAmount = ref(false);
    const withdrawals = ref();
    const currentWallet = ref({});
    const summWithComission = ref(1.05);

    const isLoading = computed(() => state.accounts.fileLoading);
    const cryptoAmount = computed(() => state.accounts.cryptoAmount);
    const accounts = computed(() => state.accounts.data);
    const wallets = computed(() => state.profile.wallets);
    const withdrawalDate = computed(() => state.accounts.withdrawalDueDate ?? Date.now() + 432000000);
    const withdrawAccount = computed(() => accounts.value.find((e) => e.id === accId.value));

    const approvedWallets = computed(() => {
      let walletsApproved = [];

      if (wallets.value.data?.length) {
        Object.entries(wallets.value.data).map((val) => {
          if (val[1].attributes.status == 'approved') {
            walletsApproved.push(val[1]);
          }
        });
      }
      return walletsApproved;
    });

    const amountValidation = () => {
      if (!rules.amount[0].trigger[1]) {
        rules.amount[0].trigger[1] = 'change';
      }
      let validate;
      // if (currentWallet.value.attributes?.system == 'BankCard') {
      //   validate = { min: 20, max: 500 };
      // } else if (currentWallet.value.attributes?.system == 'USDT(ERC20)') {
      //   validate = { min: 1000, max: 99999999 };
      // } else {
      //   validate = { min: 50, max: 99999999 };
      // }
      validate = { min: 10, max: 99999999 };

      if (validate.min > dataSource.value.amount || validate.max < dataSource.value.amount) {
        errorAmount.value = true;
        return Promise.reject(
          i18n.t(
            'messages.errorAmount',
            validate,
            dataSource.value.amount > validate.max ||
              (currentWallet.value?.id && currentWallet.value?.attributes.system == 'BankCard')
              ? 2
              : 1,
          ) + ' USD',
        );
      } else {
        errorAmount.value = false;

        return Promise.resolve();
      }
    };

    const rules = reactive({
      amount: [
        {
          trigger: ['blur'],
          type: 'number',
          validator: amountValidation,
        },
      ],
    });

    const priorityWithdrawal = [
      { label: i18n.t('start.priorityStandart'), value: 1 },
      //{ label: i18n.t('start.priorityFast'), value: 2 },
      //{ label: i18n.t('start.prioritySuperFast'), value: 3 },
      { label: i18n.t('start.standardCard'), value: 4 },
    ];
    const compSummWithComission = () => {
      let percent = dataSource.value.priority_id ? 5 : 3;
      summWithComission.value = +dataSource.value.amount * (1 + percent / 100);
    };

    const walletChange = (id) => {
      currentWallet.value = approvedWallets.value[id];
      dataSource.value.wallet_id = currentWallet.value.id;
      withdrawals.value.validate();
    };

    const sendWithdraw = () => {
      dataSource.value.account_id = accId.value;
      dataSource.value.priority = priorityWithdrawal[dataSource.value.priority_id].value;
      if (dataSource.value.wallet_id == undefined) {
        message.error(i18n.t('messages.takeWallet'));
        return;
      }
      dispatch('sendWithdrawal', dataSource.value).then(() => {
        if (props.error) {
          emit('dataNotSended');
        } else {
          emit('dataSended');
        }
      });
    };

    onMounted(() => {
      if (!wallets.value?.length) {
        dispatch('getAllWallets');
      }
    });

    return {
      depositCurrencies,
      i18n,
      dataSource,
      errorAmount,
      isLoading,
      cryptoAmount,
      withdrawAccount,
      rules,
      priorityWithdrawal,
      approvedWallets,
      wallets,
      summWithComission,
      withdrawalDate,
      withdrawals,
      compSummWithComission,
      walletChange,
      sendWithdraw,
    };
  },
};

export default WithdrawalModal;
</script>

<style lang="scss" scoped>
.withdrawal-modal {
  .ant-alert {
    margin-bottom: 20px;
  }
}
</style>
